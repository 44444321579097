import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { StateData } from './common';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  private state: StateData;
  constructor() { }

  set stateData(state) {
    this.state = state;
  }

  get stateData() {
    return this.state;
  }

  public setState(state: Partial<StateData>) {
    this.state = { ...this.state, ...state };
    
  }



  /*  Building Form    */

  private openBuildingForm = new Subject();
  public openBuildingForm$ = this.openBuildingForm.asObservable();

  public openBuilding() {
    this.openBuildingForm.next(true)
  }

  private closeBuildingForm = new Subject();
  public closeBuildingForm$ = this.closeBuildingForm.asObservable();

  public closeBuilding() {
    this.closeBuildingForm.next(true)
  }

  /*  Open openWaiverMess    */

  private openWaiverMess = new Subject();
  public openWaiverMess$ = this.openWaiverMess.asObservable();

  public openWaiverMessFun() {
    this.openWaiverMess.next(true)
  }
  /* ****************** */

  /*  Open open Notice TO onwer Mess    */

  private openNoticeMess = new ReplaySubject();
  public openNoticeMess$ = this.openNoticeMess.asObservable();

  public openNoticeMessFun() {
    this.openNoticeMess.next(true)
  }
  /* ****************** */

  /*  Open open Notice TO onwer Mess    */

  private openCondintionalMess = new ReplaySubject();
  public openCondintionalMess$ = this.openCondintionalMess.asObservable();

  public openCondintionalMessFun() {
    this.openCondintionalMess.next(true)
  }
  /* ****************** */

  /* ****************** */

  removeNullKeys(keys?: any) {
    Object.keys(keys.value).forEach((key) => (keys.value[key] == "") && delete keys.value[key]);
    Object.keys(keys.value).forEach((key) => (keys.value[key] == undefined) && delete keys.value[key]);
    Object.keys(keys.value).forEach((key) => (keys.value[key] == null) && delete keys.value[key]);
    return keys;
  }

  /* ****************** */
  /*  Remove Null Keys    */
  private viewProject = new Subject();
  public viewProject$ = this.viewProject.asObservable();

  public viewProjectFun() {
    this.viewProject.next(true)
  }
  /* ****************** */

  /*  Remove Null Keys    */
  private sideBarUpdate = new Subject();
  public sideBarUpdate$ = this.sideBarUpdate.asObservable();

  public sideBarUpdateFun() {
    this.sideBarUpdate.next(true)
  }
  /* ****************** */
  /*  Open openWaiverMess    */

  private patchValue = new Subject();
  public patchValue$ = this.patchValue.asObservable();

  public patchValueFun() {
    this.patchValue.next(true)
  }
  /* ****************** */
  /*  Open Project AutoLogin   */

  private projectDetailUpdate = new Subject();
  public projectDetailUpdate$ = this.projectDetailUpdate.asObservable();

  public projectDetailUpdateFun() {
    this.projectDetailUpdate.next(true)
  }
  /* ****************** */
  /*  Open Project AutoLogin   */

  private quickBookTokenRefreshed = new Subject();
  public quickBookTokenRefreshed$ = this.quickBookTokenRefreshed.asObservable();

  public quickBookTokenRefreshedFun() {
    this.quickBookTokenRefreshed.next(true)
  }
  /* ****************** */
}
