import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BehaviorService {
  public userData = new BehaviorSubject<any>(null);
  private routrData = new BehaviorSubject<any>(null);
  private routrDatas = new BehaviorSubject<any>(null);
  private projectID = new BehaviorSubject<any>(null);
  private siteData = new BehaviorSubject<any>(null);
  private step = new BehaviorSubject<any>(null);
  private reload = new BehaviorSubject<any>(null);
  private CategoryData = new BehaviorSubject<any>([]);
  private recomend = new BehaviorSubject<any>(null)

  private cartData = new BehaviorSubject<any>([]);
  private CallVar = new BehaviorSubject<any>(null)
  private search = new BehaviorSubject<any>(null)

  constructor() {}


setCart(data){
  if (data != undefined && data != null) {  
    this.cartData.next(data)    
}
}
getCart(){
  return this.cartData.asObservable();
}
setSearch(data){
  this.search.next(data);
}
getSearch(){
  return this.search.asObservable();
}
get(){
  return this.CallVar.asObservable();
}

fetchCategoryId(categoryList: any) {
  let catArray: any = [];
  categoryList.map((res: any) => {
    if (!res?.categoryName.match("All Products")) {
      catArray.push(res);            
      return catArray;
    }
  });        
  if (catArray?.length) {  
    // if (catArray[0]?.categoryId ==2543) {
      return catArray[0]?.categoryId;

    // }    
  }
}

getRec(){
  return this.recomend.asObservable();
}

setRec(data:any){
  if (data) {
      this.recomend.next(data);
  }
}
set(data:any){
  if (data) {
      this.CallVar.next(data);
  }
}

  setSiteData(data){
    if (data != undefined && data != null) {  
      this.siteData.next(data)    
  }
  // if (data) {
  //   this.projectID.next(data);
  // }
  }



  setReload(data: any) {
 
    if (data) {
      this.reload.next(data);
    }
  }
  getReload() {
    return this.reload.asObservable();
  }



  setCat(data: any) {
 
    if (data) {        
      this.CategoryData.next(data);
    }
  }
  getCat() {
    return this.CategoryData.asObservable();
  }







  getSiteData(){

    return this.siteData.asObservable();

  }


  setStep(data){
    if (data != undefined && data != null) {  
      this.step.next(data)    
  }

  }

  getStep(){

    return this.step.asObservable();

  }



  setProjectId(data: any) {
    if (data != undefined && data != null) {
      sessionStorage.setItem('projectId',data)
    }
    if (data) {
      this.projectID.next(data);
    }
  }
  getProjectId() {
    return this.projectID.asObservable();
  }

  routerDatas(data: any) {
    if (data) {
      this.routrDatas.next(data);
    }
  }
  getRouterDatas() {
    return this.routrDatas.asObservable();
  }

  routerData(data: any) {
    if (data) {
      this.routrData.next(data);
    }
  }
  getRouterData() {
    return this.routrData.asObservable();
  }
}
