import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { LoaderModalComponent } from "./loader-modal/loader-modal.component";
import { DashboardSidemenuComponent } from "./dashboard-sidemenu/dashboard-sidemenu.component";
import { MultipleFilterProductPipe } from "./multiple-filter-product.pipe";
import { PriceFilter } from "./pipe/pricefilter.pipe";
import { AlphabeticfilterPipe } from "./pipe/alphabeticfilter.pipe";
import { DateFilterPipe } from "./pipe/date-filter.pipe";
import { ProductnamefilterPipe } from "./pipe/productnamefilter.pipe";
import { AutocompleteOffDirective } from "./directives/autocomplete-off.directive";
import { LazySrcDirective } from "./lazy-src.directive";
import { SafeHtmlPipe } from "./pipe/safeHtml.pipe";
import { CookieConsentComponent } from "./cookie-consent/cookie-consent.component";

const cms = [ LoaderModalComponent, DashboardSidemenuComponent, LazySrcDirective];

@NgModule({
  declarations: [SafeHtmlPipe, LoaderModalComponent, DashboardSidemenuComponent, MultipleFilterProductPipe, PriceFilter,CookieConsentComponent, AlphabeticfilterPipe, DateFilterPipe, ProductnamefilterPipe, AutocompleteOffDirective, LazySrcDirective],
  imports: [
    CommonModule,
    FormsModule,
    // OwlModule
  ],
  exports: [...cms, ReactiveFormsModule,SafeHtmlPipe, FormsModule, MultipleFilterProductPipe, PriceFilter, AlphabeticfilterPipe, DateFilterPipe,CookieConsentComponent, ProductnamefilterPipe],
})
export class SharedModule {}
