import { Observable } from "rxjs";

export enum FormSteps {
  choice,
  applicationPermit,
  waiver,
  commencement,
  messagePopup,
  contractor,
  tabSubContrator,
  addSubContrator,
  conditionalRelease

}
export class StateData {
  brandsList = []
  speciesList = []
  weightList = []
  tagsList = []
  catName:any = ''
  page:number;
  resPerPage:number;
  totalPages:number;
  scroll:boolean;
  productData:[];
  dailySpecialProduct:[]
  categoryName:string;
  subCategory:string
  scrollPosition:number;
  dashboardScrollPosition:number;
  gradeFilter:any[]
  isPage:boolean
  tagFilterData:any[]
  productcount:number
  brandFilter:any[]
  cordinatesArray:any[]
  index0Productarray:any = []
  index1Productarray:any = []
  index2Productarray:any = []
  index3Productarray:any = []
  index4Productarray:any = []
  index5Productarray:any = []
  homeCategory: any = [];
  categoryList:any = []
  HeaderCategory:any =[]
  ourSpecialityContent: any = [];
  infoContent: any = [];
  aboutUs: any;
  aboutUsImage: any = [];
  brandArray1:any = []
  brandArray2:any = []
  brandArray3:any = []
  bannerArray:any = []
  marqueeText:any = []
  aeroPayUser:any=''
  constructor() {
      this.page = 1
      this.scroll = false;
      this.cordinatesArray = [];
      this.aeroPayUser = ''

      this.totalPages = 0
      this.isPage = false;
      this.productcount = 0
      this.resPerPage = 16;
      this.productData = [];
      this.dailySpecialProduct = []
      this.categoryName = '';
      this.catName = ''
      this.subCategory='';
      this.scrollPosition = 0
      this.dashboardScrollPosition = 0
      this.gradeFilter = []
      this.tagFilterData = []
      this.brandFilter = []
      this.index0Productarray = []
      this.index1Productarray = []
      this.index2Productarray = []
      this.index3Productarray = []
      this.index4Productarray = []
      this.index5Productarray = []
      this.bannerArray = []
      this.homeCategory = []
      this.categoryList = []
      this.HeaderCategory = []
      this.ourSpecialityContent =[]
      this.infoContent =[]
      this.aboutUs=''
      this.aboutUsImage=[]
      this.brandArray1=[]
      this.brandArray2=[]
      this.brandArray3=[]
      this.marqueeText = []
      this.brandsList = []
      this.speciesList = []
      this.weightList = []
      this.tagsList = []
  }
}
