import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";
import { UserService } from "../services/user.service";
import { Observable } from "rxjs";

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
  projectId:any
  constructor(
    private userSrv: UserService,

  ) {
    this.projectId = sessionStorage.getItem('projectId')
      
  }

  intercept(
    request: HttpRequest<any>,
    
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authKey: string = localStorage.getItem("token");;
    let guestKey : string = localStorage.getItem('guestKey'); 
    if(guestKey ==undefined || guestKey =='undefined'){
      guestKey = ''
    }
    if(authKey ==undefined || authKey =='undefined'){
      authKey = ''
    }   
    this.userSrv.getAuth().subscribe((res) => {
      
      if(res){
        if (authKey) {
          request = request.clone({
            headers: new HttpHeaders({
              authKey: authKey,
              'Cache-Control': 'no-cache',
              // 'Access-Control-Allow-Origin': '*',
            })
          });
        }
      }else{
        if(guestKey){
          request = request.clone({
            headers: new HttpHeaders({
              authKey: guestKey,
              'Cache-Control': 'no-cache',

              // 'Access-Control-Allow-Origin': '*',
            })
          });
        }
      }
    });    
    const fDataUrl=request.url.includes("/uploaddocument")
    const uploadProfile=request.url.includes("/uploadprofilepic")
    const loginCredential=request.url.includes("/token")
    const isFormData = fDataUrl || uploadProfile || loginCredential

    if (isFormData) {
      
    if (sessionStorage.getItem('projectId') != undefined && sessionStorage.getItem('projectId') != null) {
      request = request.clone({
        headers: request.headers.set("projectId", sessionStorage.getItem('projectId'))
  
      });
    }
      return next.handle(request);
    }
    
    if (!request.headers.has("Content-Type")) {
      request = request.clone({
      headers: request.headers.set("Content-Type", "application/json").set("Cache-Control", "no-cache"),
      });
    }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json").set("Cache-Control", "no-cache"),
   


    });

    if (sessionStorage.getItem('projectId') != undefined && sessionStorage.getItem('projectId') != null) {
      request = request.clone({
        headers: request.headers.set("projectId", sessionStorage.getItem('projectId')).set("Cache-Control", "no-cache"),
  
      });
    }    
    return next.handle(request);
  }
}
