import { Directive, OnInit, OnDestroy, AfterViewInit, Input, ElementRef, Renderer2 } from "@angular/core";

@Directive({
  selector: "[lazy-src]",
})
export class LazySrcDirective implements OnInit, OnDestroy {
  @Input("lazy-src") lazySrc: string;
  @Input("lazy-id") lazyId: string;
  @Input("debug") debug: boolean;

  private use_IntersectionObserver: boolean;
  private _intersectionObserver?: IntersectionObserver;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.use_IntersectionObserver = window["IntersectionObserver"] ? true : false;
  }

  ngOnInit() {
    this._intersectionObserver = new IntersectionObserver(
      (entries) => {
        this._CheckForIntersection(entries);
      },
      { threshold: 0.0, rootMargin: "0px 0px 256px 0px" }
    );

    this._intersectionObserver.observe(this.el.nativeElement);
  }

  private _CheckForIntersection = (entries: Array<IntersectionObserverEntry>) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      //if ( this.debug ) {console.log(entry);}
      if (this.debug) {
      }
      if (entry.isIntersecting) {
        //console.log("entry.isIntersecting")
        this.renderer.setAttribute(this.el.nativeElement, "src", this.lazySrc);
        // if (entry.intersectionRatio > 0.3 && entry.intersectionRatio < 1){
        this.renderer.removeClass(this.el.nativeElement, "img-blur");
        // }
        //console.log("image is in viewport", this.el.nativeElement.getAttribute('src'))
        // this._intersectionObserver.unobserve(<Element>( entry.target ));
        // this._intersectionObserver.disconnect();
      }
    });
  };

  // private checkIfIntersecting(entry: IntersectionObserverEntry) {
  //     return (<any>entry).isIntersecting;
  // }

  ngOnDestroy() {}
}
