import { HttpClient } from '@angular/common/http';
import { base } from '../static/baseUrl';
import { map, tap } from "rxjs/operators";
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription'; 


@Injectable({
  providedIn: 'root'
})
export class CartlengthService {

  cartLengthUpdate = new EventEmitter();    
  storedata = new EventEmitter();   
  subsVar: Subscription;    
    
  constructor() { }    
    
  cartLengthUpdateFunction(value) {    
    this.cartLengthUpdate.emit(value);    
  } 
  stpredataUpdate(value) {    
    this.storedata.emit(value);    
  } 

}
