import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { BehaviorService } from "./behaviour.service";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { LoaderModalService } from "./loader.service";
import { base } from "../static/baseUrl";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  base: any = "";
  emittedCartValue = new BehaviorSubject(false);
  private cartSource = new BehaviorSubject(0);
  cartLength = this.cartSource.asObservable();

  private messageSource = new BehaviorSubject<string>("");
  currentMessage = this.messageSource.asObservable();
  private subject = new Subject<any>();
  constructor(
    private http: HttpClient,
    private _bs: BehaviorService, 
    private titleService: Title, 
    private ls: LoaderModalService, 
    private router: Router, 
    private metaService: Meta,
   ) {
      this.base = base
    this._bs.getReload().subscribe((res) => {
      if (res == "getdata") {
        this.getSiteData();
      }
    });
  }


  Get(url: any) {
    return this.http.get(this.base + url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  getSiteData() {
    const url = `${this.base}/siteinformation/getdata`;
    let respose = this.http.get(url);
    respose.subscribe((res) => {
      this._bs.setSiteData(res["meta"]["status"]);

      if (res["meta"]["status"]) {
        localStorage.setItem("siteData", JSON.stringify(res["data"]));
        this.metaService?.updateTag( { name: 'description', content: res["data"]["metaDescription"] } );
        this._bs.setSiteData(res["meta"]["status"]);
        this.titleService.setTitle(res["data"]["headertitle"]);
        if (window?.location?.href.match("not-found")) {
          this.router.navigateByUrl("/");
        }
      }
    });
  }

  adressGets(address) {
    // &proximity=-80.19362,25.774173&bbox=-80.8736,25.515125,-80.144468,25.979434
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?country=us&limit=10&access_token=${environment.accessToken}`;
    return this.http.get(url);
  }

  getSeoContent(page) {
    const url = `${this.base}/page/seo?page=${page}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  resionList() {
    const url = `${this.base}/store/regionlist`;
    return this.http.get(url)
  }

  getTermsConditionContent() {
    const url = `${this.base}/termsandconditions/detail`;
    return this.http.get(url);
  }

  getMarqueeData(id) {
    // ?storeId=${id}
    const url = `${this.base}/marquee/marqueelist`;
    return this.http.get(url);
  }
  updateMetaKeywords(title, keywords, description) {    
    this.titleService.setTitle(title);
    this.metaService?.updateTag({ name: 'keywords', content: keywords });
    this.metaService?.updateTag( { name: 'description', content: description } );
  }
  getPrivacyPolicyContent() {
    const url = `${this.base}/privacypolicy/detail`;
    return this.http.get(url);
  }
  getHOmeContent() {
    const url = `${this.base}/homepagecontent/homepagedetails`;

    return this.http.get(url);
  }

  registerAeropay(data: any) {
    const url = `${base}/transaction/aeropaytokenforlinkaccount`
    return this.http.post(url, data)
  }

  finalRegister(data){
    const url = `${base}/transaction/linkaccountfromaggregator`
    return this.http.post(url, data)
  }
  
  getConnectedBanks(userId: any) {
    const url = `${base}/transaction/getaeropayuser?aeropayUserId=${userId}`
    return this.http.get(url)
  }
  CheckUser(body){
    const url = `${base}/transaction/checkaeropayuser`
    return this.http.post(url, body) 
  }
  verfiyMfaCode(body){
    const url = `${base}/transaction/verifyaeropayuser`
    return this.http.post(url, body) 
  }


  getUserLatestOrder() {
    const url = `${this.base}/orders/latestorder`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }
  getSpinWheelData() {
    const url = `${this.base}/spin/spindata`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }
  winAndSpinOfferFromSpinTheWheel(obj) {
    const url = `${this.base}/spin/checkandwinoffer`;
    return this.http.post(url, obj);
  }

  getUserLocation(data: any) {
    const url = `${this.base}/users/getcovauseraddress`;
    return this.http.post(url, data);
  }

  uploadProfilePic(body) {
    const url = `${this.base}/users/uploadprofilepic`;
    return this.http.post(url, body);
  }

  editUserInformation(body) {
    const url = `${this.base}/users/covauserUpdateuserAccountInfo`;
    return this.http.post(url, body);
  }

  updatePassword(body) {
    const url = `${this.base}/users/updatepassword`;
    return this.http.put(url, body);
  }

  getOrderDetails(orderId) {
    const url = `${this.base}/orders/getorder?orderId=${orderId}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }
  getFaqList() {
    const url = `${this.base}/faq/listfaqnew`;
    return this.http.post(url, "");
  }
  getOrderList(itemPerPage, pageNumber) {
    const url = `${this.base}/orders/getorder?itemPerPage=${itemPerPage}&pageNumber=${pageNumber}`;
    return this.http.get(url);
  }

  createRoom(obj) {
    const url = `${this.base}/channel/getchannel`;
    return this.http.post(url, obj);
  }

  Post(url: any, context: any) {
    return this.http.post(this.base + url, context).pipe(
      map((response: any) => {
        return response;
      })
      // catchError(this.handleError)
    );
  }

  PUT(url: any, context: any) {
    return this.http.put(this.base + url, context).pipe(
      map((response: any) => {
        return response;
      })
      // catchError(this.handleError)
    );
  }

  deleteCard(body: any) {
    const url = `${this.base}/users/deletecardinfo`;
    return this.http.post(url, body);
  }
  getOrderListForPagination(itemPerPage, pageNumber) {
    const url = `${this.base}/orders/getorder?itemPerPage=${itemPerPage}&pageNumber=${pageNumber}`;
    return this.http.get(url).pipe(map((res) => res));
  }

  referAFriend() {
    const url = `${this.base}/users/referafriend`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  walletHistory() {
    const url = `${this.base}/userwallet/wallethistory`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  rewardHistory() {
    const url = `${this.base}/userwallet/rewardhistory`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  getWishlistDataAccordingToStore(storeId) {
    const url = `${this.base}/wishlists/getwishlist?storeId=${storeId}`;
    return this.http.get(url);
  }

  deleteProductFromWishlist(body) {
    const url = `${this.base}/wishlists/deletewishlist`;
    return this.http.put(url, body);
  }

  affiliateReporting(level, code) {
    const url = `${this.base}/reporting/affiliatereporting?affiliateLevel=${level}&affiliateCode=${code}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  // logoutUser() {
  //   const url = `${this.base}/users/logout`
  //   return this.http.get(url)
  // }

  logoutUser(signintype) {
    if (signintype == undefined || signintype == null || signintype == "") {
      signintype = "";
    }
    const url = `${this.base}/users/logout?signintype=${signintype}`;
    return this.http.get(url);
  }

  verifyDOB(obj) {
    const url = `${this.base}/users/verifydob`;
    return this.http.put(url, obj);
  }

  getUserProfile(userId: any) {
    const url = `${this.base}/users/getcovauserprofile/${userId}`;
    return this.http.get(url);
  }
  getCategoryList() {
    let storeId = localStorage.getItem("localStoreId");
    const url = `${this.base}/categories/getstorecategorynew?storeId=${storeId}`;

    return this.http.get(url);
  }

  getCategoryLists() {
    const url = `${this.base}/categories/getcategory`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  getHomeProductList(storeId) {
    const url = `${this.base}/products/homeproductlist?storeId=${storeId}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  getFeatureProductList(storeId) {
    const url = `${this.base}/products/featuredproduct?storeId=${storeId}`;
    return this.http.get(url);
  }

  getBannerForDesktop(storeId) {
    const url = `${this.base}/banners/getbanner?bannerType=NORMAL&storeId=${storeId}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  //for mobile banner
  getBannerForMobile(storeId) {
    const url = `${this.base}/banners/getbanner?bannerType=NORMAL&storeId=${storeId}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  getStoreList() {
    let storeId = localStorage.getItem("localStoreId");
    const url = `${this.base}/store/list?storeId=${storeId}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  getPageContent(pageNumber) {
    const url = `${this.base}/page/pagecontent?pageNumber=${pageNumber}`;
    return this.http.get(url);
  }

  getDefaultDiscount() {
    const url = `${this.base}/carts/defaultpromocode`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  //recommended
  getRecommendedPro(obj) {
    const url = `${this.base}/storeproducts/productrecommendations`;
    return this.http.post(url, obj);
  }

  //clear cart logged case
  UserCartClear() {
    const url = `${this.base}/carts/removecart`;
    return this.http.get(url);
  }

  getHomePageProduct(storeId) {
    const url = `${this.base}/storeproducts/bystore?storeId=${storeId}`;
    return this.http.get(url);
  }

  getNewArrivalProducts(storeId) {
    const url = `${this.base}/storeproducts/getlatestproducts?storeId=${storeId}`;
    return this.http.get(url);
  }

  getTagList() {
    const url = `${this.base}/storeproducts/taglist`;
    return this.http.get(url);
  }

  getEffectList() {
    const url = `${this.base}/storeproducts/effectList`;
    return this.http.get(url);
  }

  getSpeciesList() {
    const url = `${this.base}/storeProducts/specieslist`;
    return this.http.get(url);
  }

  getPurchasaeLimit() {
    const url = `${this.base}/storeproducts/getpurchaselimit`;
    return this.http.get(url);
  }

  getNewArrivalProducts2(storeId, resPerPage, page) {
    const url = `${this.base}/storeproducts/getalllatestproducts?storeId=${storeId}&page=${page}&resPerPage=${resPerPage}`;
    return this.http.get(url);
  }

  getDashboardFlowerProducts(storeId) {
    const url = `${this.base}/storeproducts/getflowerproducts?storeId=${storeId}`;
    return this.http.get(url);
  }

  index0Product(storeId) {
    const url = `${this.base}/storeproducts/zeroindexcategoryproducts?storeId=${storeId}`;
    return this.http.get(url);
  }

  index1Product(storeId) {
    const url = `${this.base}/storeproducts/firstindexcategoryproducts?storeId=${storeId}`;
    return this.http.get(url);
  }

  
  index2Product(storeId) {
    const url = `${this.base}/storeproducts/secondindexcategoryproducts?storeId=${storeId}`;
    return this.http.get(url);
  }

  index3Product(storeId) {
    const url = `${this.base}/storeproducts/thirdindexcategoryproducts?storeId=${storeId}`;
    return this.http.get(url);
  }
  
  index4Product(storeId) {
    const url = `${this.base}/storeproducts/fourthindexcategoryproducts?storeId=${storeId}`;
    return this.http.get(url);
  }
  
  index5Product(storeId) {
    const url = `${this.base}/storeproducts/fifthindexcategoryproducts?storeId=${storeId}`;
    return this.http.get(url);
  }






  getConsentartionProducts(storeId) {
    const url = `${this.base}/storeproducts/getconcentratesproducts?storeId=${storeId}`;
    return this.http.get(url);
  }

  dashboardEdibleProducts(storeId) {
    const url = `${this.base}/storeproducts/getedibleproducts?storeId=${storeId}`;
    return this.http.get(url);
  }

  dashboardPreRollsProducts(storeId) {
    const url = `${this.base}/storeproducts/getprerollsproducts?storeId=${storeId}`;
    return this.http.get(url);
  }

  getHomePageProduct1() {
    const url = `${this.base}/products/getstoreproductforhome`;
    return this.http.get(url);
  }

  getFlowerProduct(catId, page) {
    const url = `${this.base}/products/getflowerproducts?storeId=${catId}&page=${page}`;
    return this.http.get(url);
  }

  getFlowerProductViewAll(storeId, categoryId, resPerPage, page) {
    const url = `${this.base}/storeproducts/productsstoreandcategorywise?storeId=${storeId}&categoryId=${categoryId}&resPerPage=${resPerPage}&page=${page}`;
    return this.http.get(url);
  }

  getLatestProduct(page) {
    const url = `${this.base}/products/getcovaproductsall`;
    return this.http.get(url);
  }

  shopAllProduct(storeId, page) {
    const url = `${this.base}/storeproducts/getalllatestproducts?storeId=${storeId}&page=${page}&resPerPage=12`;
    return this.http.get(url);
  }

  getConcetrationProduct(catId, page) {
    const url = `${this.base}/products/getconcentratesproducts?storeId=${catId}&page=${page}`;
    return this.http.get(url);
  }

  viewAllGetConcetrationProduct(storeId, categoryId, resPerPage, page) {
    const url = `${this.base}/storeproducts/productsstoreandcategorywise?storeId=${storeId}&categoryId=${categoryId}&resPerPage=${resPerPage}&page=${page}`;
    return this.http.get(url);
  }

  getEdibleProduct(catId, page) {
    const url = `${this.base}/products/getedibleproducts?storeId=${catId}&page=${page}`;
    return this.http.get(url);
  }

  viesAllGetEdibleProduct(storeId, categoryId, resPerPage, page) {
    const url = `${this.base}/storeproducts/productsstoreandcategorywise?storeId=${storeId}&categoryId=${categoryId}&resPerPage=${resPerPage}&page=${page}`;
    return this.http.get(url);
  }

  getPreRollsProduct(catId, page) {
    const url = `${this.base}/products/prerolls?storeId=${catId}&page=${page}`;
    return this.http.get(url);
  }

  viewAllGetPreRollsProduct(storeId, categoryId, resPerPage, page) {
    const url = `${this.base}/storeproducts/productsstoreandcategorywise?storeId=${storeId}&categoryId=${categoryId}&resPerPage=${resPerPage}&page=${page}`;
    return this.http.get(url);
  }

  getProductList() {
    const url = `${this.base}/products/getcovaproductsall`;
    return this.http.get(url);
  }

  //check varirance
  getVarianceOfProduct(id) {
    const url = `${this.base}/products/variations?CatalogItemId=${id}`;
    return this.http.get(url);
  }

  addGuestuser(obj) {
    const url = `${this.base}/users/guestaddtocart`;
    return this.http.post(url, obj);
  }

  getNew_timeSlot(storeid) {
    const url = `http://3.96.41.52:8888/api/user/timeslot/timeslotlist?storeId=${storeid}`;
    return this.http.get(url);
  }

  guestOrderPlace(obj) {
    const url = `${this.base}/orders/guestuserorderplace`;
    return this.http.post(url, obj);
  }

  getGuestOrderList() {
    const url = `${this.base}/orders/guestuserorderlist`;
    return this.http.get(url);
  }

  getXML() {
    const url = `${this.base}/sitmap/sitemap.xml`;
    return this.http.get(url);
  }

  addContact(body) {
    const url = `${this.base}/contact/add`;
    return this.http.post(url, body);
  }

  changeMessage(message) {
    this.messageSource.next(message);
  }
  //
  register(body) {
    const url = `${this.base}/users/signup`;
    return this.http.post(url, body);
  }
  login(body) {
    const url = `${this.base}/users/signin`;
    return this.http.post(url, body);
  }
  sendOTP(body) {
    const url = `${this.base}/users/generatemobile`;
    return this.http.post(url, body);
  }
  verifyOTP(body) {
    const url = `${this.base}/users/verifymobile`;
    return this.http.post(url, body);
  }
  updateProfile(body) {
    const url = "https://api.localcannabisco.ca/admin/cova/users/updateuserslist";
    return this.http.post(url, body);
  }
  uploadUserDocument(body) {
    const url = `${this.base}/users/uploaddocument`;
    return this.http.post(url, body);
  }
  checkUserAddressValid(formData) {
    const url = `${this.base}/users/checkpostalcode`;
    return this.http.post(url, formData);
  }
  checkPostalCodeInStore(body) {
    const url = `${this.base}/users/checkpostalcodeforstore`;
    return this.http.post(url, body);
  }
  saveAddress(body) {
    const url = `${this.base}/users/covauseaddaddress`;
    return this.http.post(url, body);
  }
  getGuestTax(obj) {
    const url = `${this.base}/carts/calculatetaxbyproducrid`;
    return this.http.post(url, obj);
  }

  getAddress(data: any) {
    const url = `${this.base}/users/getcovauseraddress`;
    return this.http.post(url, data);
  }
  getUserData() {
    const url = `${this.base}/users/getMobileVerifiedAndGovernmentVerified`;
    // return this.http.get(url).pipe(map(res => res['data']))
  }

  resendOTP(body) {
    const url = `${this.base}/users/resendotp`;
    return this.http.post(url, body);
  }
  sendEmailForForgotPassword(emailId) {
    const url = `${this.base}/users/sendemailforforgotpassword?emailId=${emailId}`;
    return this.http.get(url);
  }
  forgotPassword(body) {
    const url = `${this.base}/users/forgotpassword`;
    return this.http.post(url, body);
  }
  sendMailToEmailId(body) {
    const url = `${this.base}/users/sendotponmail`;
    return this.http.put(url, body);
  }
  verifyEmailId(body) {
    const url = `${this.base}/users/verifyemail`;
    return this.http.put(url, body);
  }
  changePasswordForFirstTime(body) {
    const url = `${this.base}/users/resetpasswordforfirsttime`;
    return this.http.put(url, body);
  }
  defaultStore() {
    const url = `${this.base}/store/defaultstore`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }
  submitApkRequest(obj) {
    const url = `${this.base}/application/addapplicationdownload`;
    return this.http.post(url, obj);
  }

  loginCredential(obj) {
    const url = `https://accounts.iqmetrix.net/v1/oauth2/token`;
    return this.http.post(url, obj);
  }
  pickUpStore(PostalCode: any) {
    const url = `${this.base}/store/getcovastorelist?postalCode=` + PostalCode; //new

    return this.http.get(url).pipe(map((res) => res["data"]));
  }
  findNearestStore(obj) {
    const url = `${this.base}/store/findneareststore`;
    return this.http.post(url, obj);
  }

  //new

  checkPostalCode(obj) {
    const url = `${this.base}/store/checkpostalcode`;
    return this.http.post(url, obj);
  }
  getStoreDetail(obj) {
    const url = `${this.base}/store/covastoredetailbystoreid`;
    return this.http.post(url, obj);
  }

  getHomeCategories() {
    const url = `${this.base}/hamePageCategories/getlist`;
    return this.http.get(url);
  }

  getStoreProductList() {
    let storeId = localStorage.getItem("localStoreId");
    const url = `${this.base}/storeproducts/list?storeId=${storeId}`;
    return this.http.get(url);
  }

  getStoreProductListByCategoryId(storeId, categoryId, page) {
    const url = `${this.base}/storeproducts/productsstoreandcategorywise?storeId=${storeId}&categoryId=${categoryId}&resPerPage=8&page=${page}`;
    return this.http.get(url);
  }

  getStoreProductListByCategoryIdnew(storeId, categoryId, page) {
    const url = `${this.base}/storeproducts/productsstoreandcategorywisenew?storeId=${storeId}&categoryId=${categoryId}&resPerPage=8&page=${page}`;
    return this.http.get(url);
  }

  getStoreProductListBySubCategoryIdnew(storeId, subCategoryId, page) {
    const url = `${this.base}/storeproducts/productsstorewisesubcategorynew?storeId=${storeId}&subCategoryId=${subCategoryId}&resPerPage=8&page=${page}`;
    return this.http.get(url);
  }

  getStoreProductListBySubCategoryId(storeId, subCategoryId, page) {
    const url = `${this.base}/storeproducts/productsstorewisesubcategory?storeId=${storeId}&subCategoryId=${subCategoryId}&resPerPage=8&page=${page}`;
    return this.http.get(url);
  }

  getProductDetails(storeId, slug) {
    const url = `${this.base}/storeproducts/productdetailsbyslug?storeId=${storeId}&productslug=${slug}`;
    return this.http.get(url);
  }

  getBrandProduct(obj) {
    const url = `${this.base}/storeproducts/recommendedproductlist`;
    return this.http.post(url, obj);
  }

  getProductByStoreIDAndCategoryName(storeId, categoryName) {
    const url = `${this.base}/products/productbycategory?storeId=${storeId}&categoryName=${categoryName}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  getProductDetailsByIdAndStoreId(productId, storeId, userId) {
    const url = `${this.base}/products/productbyidandstoreid?productDisplayId=${productId}&storeId=${storeId}&userId=${userId}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  addProductToWishListInDB(body) {
    const url = `${this.base}/wishlists/addwishlist`;
    return this.http.post(url, body);
  }

  addReview(body) {
    const url = `${this.base}/products/addreview`;
    return this.http.post(url, body);
  }

  reviewList(productId) {
    const url = `${this.base}/products/reviewlist?productId=${productId}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  searchProduct(searchKey, storeId) {
    const url = `${this.base}/products/searchproduct?searchKey=${searchKey}&storeId=${storeId}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  searchSingleProduct(storeId, search) {
    // const url = `${this.base}/products/getcovasearchproductdata?search=${search}&page=1&itemPerPage=2`
    const url = `${this.base}/storeproducts/searchproductsstore?searchKey=${search}&storeId=${storeId}&resPerPage=20&page=1`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  searchSingleProductnew(obj) {
    //suggestion
    const url = `${this.base}/storeproducts/smartsearchsuggestionproductsstore`;
    return this.http.post(url, obj);
    /*  storeproducts/smartsearchproductsstore */
  }

  categoriesDetailsAccordingToName(name) {
    const url = `${this.base}/products/categoriesdetails?categoryName=${name}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  filterProductOnTheBasisOfPrice(obj) {
    const url = `${this.base}/products/filterproductaccordingtoprice`;
    return this.http.post(url, obj).pipe(map((res) => res["data"]));
  }

  getProductListAccordingToFilter(obj) {
    const url = `${this.base}/products/productlistwithfilter`;
    return this.http.post(url, obj);
  }

  brandlistwithallInfo(resPerPage, page) {
    const url = `${this.base}/storeproducts/brandlist?resPerPage=${resPerPage}&page=${page}`;
    return this.http.get(url);
  }

  getBrandDetails(id) {
    const url = `${this.base}/storeproducts/branddetail?brandId=${id}`;
    return this.http.get(url);
  }

  productWithFilter(obj) {
    const url = `${this.base}/storeproducts/productlistwithfilterandpagination`;
    return this.http.post(url, obj);
  }

  productWithFilternew(obj) {
    const url = `${this.base}/storeproducts/productlistwithfilterandpaginationnew`;
    return this.http.post(url, obj);
  }

  productFilterList() {
    const url = `${this.base}/storeproducts/filtertypelist?storeId=274420`;
    return this.http.get(url);
  }
  placeOrderAPI(body) {
    const url = `${this.base}/orders/orderplace`;
    return this.http.post(url, body);
  }
  placeMailOrder(body) {
    const url = `${this.base}/orders/mailorderplace`;
    return this.http.post(url, body);
  }
  userWalletRewardPoint() {
    const url = `${this.base}/userwallet/walletreward`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }
  getStoresTaxes() {
    let storeId = localStorage.getItem("localStoreId");
    const url = `${this.base}/store/storetaxes?storeId=${storeId}`;
    return this.http.get(url);
  }
  cancelOrder(body) {
    let url = `${this.base}/orders/cancelorder`;
    return this.http.post(url, body);
  }

  getTimeSlotArrayFromDatabase(currentDay) {
    let storeId = localStorage.getItem("localStoreId");
    let url = `${this.base}/timeslot/timeslotlist?storeId=${storeId}&day=${currentDay}`;
    return this.http.get(url).pipe(map((res) => res["data"]));
  }

  changeOrderPaymentStatus(obj) {
    let url = `${this.base}/orders/updatecovaorderstatus`;
    return this.http.post(url, obj);
  }
  callPaymentProcessApi(obj) {
    let url = `${this.base}/transaction/cardpayment`;
    return this.http.post(url, obj);
  }

  updateCovaPaymentStatus(obj) {
    let url = `${this.base}/orders/updatepaymentstatusincova`;
    return this.http.post(url, obj);
  }

  getSuggestionSearchList() {
    let url = `${this.base}/storeproducts//searchtermslist`;
    return this.http.get(url);
  }

  go_invokeCartResetFunction_forguest() {
    this.subject.next('');
  }
  invokecartGuestFunction(): Observable<any> {
    return this.subject.asObservable();
  }

  addProductInCart(body) {
    const url = `${this.base}/carts/addtocart`;
    return this.http.post(url, body).pipe(
      tap((res) => {
        if (res["meta"]["status"]) {
          this.emittedCartValue.next(true);
        }
      })
    );
  }

  addProductInCartnew(body) {
    const url = `${this.base}/carts/addtocartnew`;
    return this.http.post(url, body);
  }
  addProductInCartnewforWthourRecomndPRD(body) {
    const url = `${this.base}/carts/addtocartnew?recommended=NO`;
    return this.http.post(url, body);
  }

  addBundleProductInCart(obj) {
    const url = `${this.base}/carts/addmixmatchproduct`;
    return this.http.post(url, obj).pipe(
      tap((res) => {
        if (res["meta"]["status"]) {
          this.emittedCartValue.next(true);
        }
      })
    );
  }
  getUserCartProduct(isSwitchStatus?: any, storeID?: any) {
    const url = `${this.base}/carts/getcartproduct?storeId=` + storeID + "&isSwitch=" + isSwitchStatus;
    return this.http.get(url);
  }

  getUserCartProductNew(isSwitchStatus, storeID) {
    const url = `${this.base}/carts/getcartproduct?storeId=` + storeID + "&isSwitch=" + isSwitchStatus;
    return this.http.get(url);
  }
  getCartProductWithoutRecomandedPrd(isSwitchStatus, storeID) {
    const url = `${this.base}/carts/getcartproduct?storeId=` + storeID + "&isSwitch=" + isSwitchStatus + "&recommended=NO";
    return this.http.get(url);
  }
  getRecomandedCartProduct() {
    const url = `${this.base}/carts/recommendedproductlist`;
    return this.http.get(url);
  }
  updateCartProduct(cartLength) {
    this.cartSource.next(cartLength);
  }
  emitCartData(): Observable<boolean> {
    return this.emittedCartValue.asObservable();
  }
  removeProductFromCart(body) {
    const url = `${this.base}/carts/removefromcartnew`;
    return this.http.post(url, body);
  }
  increaseProductQuantityInCart(obj) {
    const url = `${this.base}/carts/addtocartnew`;
    obj["token"] = localStorage.getItem("covatoken");
    return this.http.post(url, obj).pipe(
      tap((res) => {
        if (res["meta"]["status"]) {
          this.emittedCartValue.next(true);
        } else {
          this.emittedCartValue.next(true);
        }
      })
    );
  }
  addOfflineProductInCart(obj) {
    const url = `${this.base}/carts/addOfflineproductincart`;
    return this.http.post(url, obj).pipe(
      tap((res) => {
        if (res["meta"]["status"]) {
          this.emittedCartValue.next(true);
        }
      })
    );
  }

  applyPromoCode(obj) {
    const url = `${this.base}/carts/applypromocode`;
    return this.http.post(url, obj);
  }

  removePromoCode() {
    const url = `${this.base}/carts/removepromocode`;
    return this.http.get(url);
  }
  applyPreOrderCode() {
    const url = `${this.base}/carts/applypreordercodeincart`;
    return this.http.get(url);
  }
  removePreOrderCode() {
    const url = `${this.base}/carts/removepreordercodeincart`;
    return this.http.get(url);
  }

  guestCartValueCheck(obj) {
    const url = `${this.base}/carts/checkmincartvalue`;
    return this.http.post(url, obj);
  }

  handleError(error: HttpErrorResponse) {
    if (error?.error?.code == 401) {
      return throwError("");
    } else if (error?.error?.code == 404) {
      return throwError(error.error.message);
    } else if (error?.error?.code == 400) {
      return throwError(error?.error?.message);
    } else if (error?.error?.code == 500) {
      return throwError(error?.error?.message);
    }
    return throwError("Something bad happened; please try again later.");
  }
}
