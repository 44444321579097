import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multipleFilterProduct'
})
export class MultipleFilterProductPipe implements PipeTransform {
  transform(items: any[], typeString?: string, gradeString?: string, quantityString?: string): any {
    if (!items) return [];
    if (!typeString && !gradeString && !quantityString) {
      return items;
    }
    else {
      // Only Type is selected
      if (typeString && !gradeString && !quantityString) {
        items = [...items.filter(item => {
          return item.cateType ? typeString.includes(item.cateType) : false;
        })];
      }
      // only Grade is selected
      else if (!typeString && gradeString && !quantityString) {
        var gradeUpdateString = this.removeLastComma(gradeString)
        let grade = gradeUpdateString.split(",")
        items = [...items.filter(item => grade.includes(item.grade))]
      }
      //only quanity is selected
      else if (!typeString && !gradeString && quantityString) {
        var quantityUpdateString = this.removeLastComma(quantityString)
        let quantity = quantityUpdateString.split(",")
        items = [...items.filter(item => quantity.includes(item.quantity))]
        // items = [...items.filter(item => gradeString.toLocaleLowerCase().includes(item.grade.toLocaleLowerCase()))];
      }
      else if (typeString && gradeString && !quantityString) {
        typeString = typeString.toLocaleLowerCase();
        items = [...items.filter(item => {
          return item.cate_type ? typeString.toLocaleLowerCase().includes(item.cate_type.toLocaleLowerCase()) : false;
        })];
        var gradeUpdateString = this.removeLastComma(gradeString)
        let grade = gradeUpdateString.split(",")
        items = [...items.filter(item => grade.includes(item.grade))]
        // gradeString = gradeString.toLocaleLowerCase();
        // items = [...items.filter(item => gradeString.toLocaleLowerCase().includes(item.grade.toLocaleLowerCase()))];
      }
      else if (typeString && !gradeString && quantityString) {
        typeString = typeString.toLocaleLowerCase();
        items = [...items.filter(item => {
          return item.cate_type ? typeString.toLocaleLowerCase().includes(item.cate_type.toLocaleLowerCase()) : false;
        })];
        var quantityUpdateString = this.removeLastComma(quantityString)
        let quantity = quantityUpdateString.split(",")
        items = [...items.filter(item => quantity.includes(item.quantity))]
        // quantityString = quantityString.toLocaleLowerCase();
        // items = [...items.filter(item => quantityString.toLocaleLowerCase().includes(item.quantity.toLocaleLowerCase()))];
      }
      else if (!typeString && gradeString && quantityString) {
        var gradeUpdateString = this.removeLastComma(gradeString)
        let grade = gradeUpdateString.split(",")
        items = [...items.filter(item => grade.includes(item.grade))]
        var quantityUpdateString = this.removeLastComma(quantityString)
        let quantity = quantityUpdateString.split(",")
        items = [...items.filter(item => quantity.includes(item.quantity))]
        // gradeString = gradeString.toLocaleLowerCase();
        // items = [...items.filter(item => gradeString.toLocaleLowerCase().includes(item.grade.toLocaleLowerCase()))];
        // quantityString = quantityString.toLocaleLowerCase();
        //items = [...items.filter(item => quantityString.toLocaleLowerCase().includes(item.quantity.toLocaleLowerCase()))];
      }
      else {
        typeString = typeString.toLocaleLowerCase();
        items = [...items.filter(item => {
          return item.cate_type ? typeString.toLocaleLowerCase().includes(item.cate_type.toLocaleLowerCase()) : false;
        })];
        var gradeUpdateString = this.removeLastComma(gradeString)
        let grade = gradeUpdateString.split(",")
        items = [...items.filter(item => grade.includes(item.grade))]
        var quantityUpdateString = this.removeLastComma(quantityString)
        let quantity = quantityUpdateString.split(",")
        items = [...items.filter(item => quantity.includes(item.quantity))]
        //gradeString = gradeString.toLocaleLowerCase();
        //items = [...items.filter(item => gradeString.toLocaleLowerCase().includes(item.grade.toLocaleLowerCase()))];

        // quantityString = quantityString.toLocaleLowerCase();
        // items = [...items.filter(item => quantityString.toLocaleLowerCase().includes(item.quantity.toLocaleLowerCase()))];

      }
      return items;
    }

  }
  removeLastComma(data) {
    var n = data.lastIndexOf(",");
    var a = data.substring(0, n)
    return a;
  }
}
