<div class="dashboard-left-menu">
  <ul>
    <li>
      <a href="javascript:;" [class.active]="data[0] === 'profile'" [ngStyle]="data[0]=='profile' && {'border': '2px solid #000000'}" (click)="redirectToUsePage('/dashboard/profile')" routerLinkActive="active"> <img src="assets/images/dasboard-user.png" alt="" /> my Profile </a>
    </li>
    
    <!-- <li>
      <a href="javascript:;" [class.active]="data[0] === 'card'" [ngStyle]="data[0]=='card' && {'border': '2px solid #000000'}" (click)="redirectToUsePage('/dashboard/card')" routerLinkActive="active"><img src="assets/images/money-management.png" alt="" />Billing information</a>
    </li> -->
    <li>
      <a href="javascript:;" [class.active]="data[0] === 'change-password'" [ngStyle]="data[0]=='change-password' && {'border': '2px solid #000000'}" (click)="redirectToUsePage('/dashboard/change-password')" routerLinkActive="active"> <img src="assets/images/change-password.png" alt="" />Change password</a>
    </li>

    <!-- <li>
      <a href="javascript:;"[class.active]="data[0] === 'payment-setup'"(click)="redirectToUsePage('/dashboard/payment-setup')"routerLinkActive="active"><img src="assets/images/package.png" alt="" />Payments</a>
    </li> -->

    <li>
      <a href="javascript:;" [class.active]="data[0] === 'order-list'" [ngStyle]="data[0]=='order-list' && {'border': '2px solid #000000'}" (click)="redirectToUsePage('/dashboard/order-history')" routerLinkActive="active"><img src="assets/images/file.png" alt="" />History</a>
    </li>

    <li>
      <a href="javascript:;" [class.active]="data[0] === 'order-details'" [ngStyle]="data[0]=='order-details' && {'border': '2px solid #000000'}" (click)="redirectToUsePage('/dashboard/order-details')" routerLinkActive="active"><img src="assets/images/package.png" alt="" />Orders</a>
    </li>

    <li>
      <a href="javascript:;" [class.active]="data[0] === 'fav'" [ngStyle]="data[0]=='fav' && {'border': '2px solid #000000'}" (click)="redirectToUsePage('/dashboard/favs')" routerLinkActive="active"><img src="assets/images/favorite.png" alt="" />My Favs</a>
    </li>
  </ul>
</div>