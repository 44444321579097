import { Component, OnInit } from "@angular/core";
import { UserService } from "./services/user.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DataLayerServiceService } from "./data-layer-service.service";
import { CommonService } from "./services/common.service";
import { AppStateService } from "./shared/app-state.service";
import { StateData } from "./shared/common";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  token: any = "";
  constructor(
     private userSrv: UserService, 
     private commonService: CommonService, 
     private router: Router, 
     private _dataLayerService: DataLayerServiceService,
     private activatedRoute: ActivatedRoute,
     private appStateSvc: AppStateService,
    ) {
    this.appStateSvc.stateData = new StateData();      
    localStorage.setItem("optionType", "PICKUP");
    localStorage.setItem("isPickUpOrder", "true");
    // This is for sideNav Component....
    this.getUrlData();
    this.activatedRoute.queryParams.subscribe((res) => {
      this.token = res.token;
      if (this.token != undefined && this.token != null && this.token != "") {
        localStorage.setItem("token", this.token);
      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._dataLayerService.logPageView(event.url); //call our dataLayer service's page view method to ping home with the url value
      }
    });
  }
  getUrlData() {
    this.commonService.getSiteData();
    let age = localStorage.getItem("ageVerify");   
    let localStoreId = localStorage.getItem("localStoreId");    
    if (!localStoreId && age == undefined && window.location.href =='https://highlifehealth.cannalogic.co/' ) {
      this.router.navigateByUrl("verify/age");
      return
    }
  }

  ngOnInit() {
  
    const allReadyLoggedIn = localStorage.getItem("covaUserId");
    if (allReadyLoggedIn) {
      const user = this.getLocalStorage();
      this.afterLogin(user);
    }
  }
  
  getLocalStorage() {
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const mobileVerified = localStorage.getItem("mobileVerified");
    const documentVerify = localStorage.getItem("documentVerify");

    const user = {
      userId,
      userName,
      mobileVerified,
      documentVerify,
    };
    return user;
  }
  afterLogin(user) {
    this.userSrv.updateUserAuth(true, user);
  }
}
