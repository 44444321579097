import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-sidemenu',
  templateUrl: './dashboard-sidemenu.component.html',
  styleUrls: ['./dashboard-sidemenu.component.css']
})
export class DashboardSidemenuComponent implements OnInit {
  @Input() data: any;

  constructor(private router: Router) { }

  ngOnInit(): void {    
  }

  redirectToUsePage(data) {
    this.router.navigateByUrl(data)
  }

}
