import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutocompleteOff]'
})
export class AutocompleteOffDirective {

  constructor(private autoCompEl: ElementRef) {
    this.autoCompEl.nativeElement.setAttribute('autocomplete', 'off');
    this.autoCompEl.nativeElement.setAttribute('autocorrect', 'off');
    this.autoCompEl.nativeElement.setAttribute('autocapitalize', 'none');
    this.autoCompEl.nativeElement.setAttribute('spellcheck', 'false');
    // this.autoCompEl.nativeElement.setAttribute('autocomplete', 'do-not-autofill');
    // this.autoCompEl.nativeElement.setAttribute('autocomplete', 'false');
  }

}
