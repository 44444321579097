import { Injectable } from '@angular/core';
import { WindowRefrenceServiceService } from './window-refrence-service.service';
declare var gtag

@Injectable({
  providedIn: 'root'
})
export class DataLayerServiceService {

  private window; 

  constructor (private _windowRef: WindowRefrenceServiceService)
  {
      this.window = this._windowRef.nativeWindow; // intialise the window to what we get from our window service

  }

   private pingHome(obj)
   {
    this.window.dataLayer.push({ ecommerce: null })
       if(obj)  
      //  this.window.dataLayer.push(obj);
       gtag(obj);
   }
  
  
  //list of all our dataLayer methods

  logPageView(data)
  {
      const hit = {
         event: 'view_item',
        'ecommerce':{
      //     products:  [{  
      //       'name':data.productDetails.ProductName,
      //   'id':data.CatalogItemId,
      //   'price':data.myPrice,
      //   'brand':data.productDetails.Brand,
      //   'category': '',
      //   'variant':'',
      //   'position': ''

      // }]
      items: [
        {
          item_id: data.CatalogItemId,
          item_name: data.productDetails ? data.productDetails.ProductName:'',
          affiliation: "Local",
          coupon: " ",
          currency: "CAD",
          discount: 0,
          index: 0,
          item_brand: data.productDetails ? data.productDetails.Brand:'',
          item_category: "item_category",
          item_category2: "item_categor2",
          item_category3: "item_category3",
          item_category4: "item_categor4",
          item_category5: "item_category5",
          item_list_id: data.CatalogItemId,
          item_list_name:data.productDetails ? data.productDetails.ProductName:'',
          item_variant: "Local",
          location_id: "location",
          price: data.myPrice,
          quantity: 0
        }
      ]
        }
        

      };
      this.pingHome(hit);
  }

  logEvent(event,category,action,label)
  {
      const hit = {
          event:event,
          category:category,
          action:action,
          label: label
      }
     

       this.pingHome(gtag);
  }
  OrderPlace(data:any ){
    // data.forEach(e=>{
      let  itemsss
      data.productDetails.forEach(e=>{
        itemsss= [
          // If someone purchases more than one item, 
          // you can add those items to the items array
           {
            item_id:e.inventoryId,
            item_name: e.productName, //."Stan and Friends Tee",
            affiliation: "Local",
            coupon: "Local",
            currency: "CAD",
            discount:data.discount,
            index: 0,
            item_brand: "Local",
            item_category: "item_category",
            item_category2: "item_category2",
            item_category3: "item_category3",
            item_category4: "item_category4",
            item_category5: "item_category5",
            item_list_id: "item_list_id",
            item_list_name: e.productName,
            item_variant: "Local",
            location_id: "location",
            price:e.price*e.selectQuantity,
            quantity:e.selectQuantity
          }]

      })
    
      const hit={
        'event':'purchase',
        'ecommerce':{
          transaction_id:data.orderDisplayId,
          affiliation: "Local",
          value: data.subTotal,
          tax: data.tax,
          shipping:"Local",
          currency: "CAD",
          coupon: "Local",
            items:itemsss
        }
        
  
      }

   
      this.pingHome(hit);

     

    // })
    
      

  }
  addToCartGTM(data:any ){
    //(data,)
  
    data.forEach(e=>{
      const hit={
        event:'add_to_cart',
        'ecommerce':{
          currency: "CAD",
          value:e.price*e.selectQuantity,
          items: {
            'item_id':e.inventoryId,
            'item_name': e.productName,
            affiliation:"Local",
            coupon: "",
            currency: "CAD",
            discount: 0,
            index: 0,
            item_brand: 0,
            item_category: "item_category",
            item_category2: "item_category2",
            item_category3: "item_category3",
            item_category4: "item_category4",
            item_category5: " item_category5",
            item_list_id: e.inventoryId,
            item_list_name: e.productName,
            item_variant: "Local",
            location_id: "location",
       'price':e.price*e.selectQuantity,
       'quantity': e.selectQuantity
        }
        }
      
    }
    // 
    // currency: "USD",
    // value: 7.77,
    // items: [
    //   {
    //     item_id: "SKU_12345",
    //     item_name: "Stan and Friends Tee",
    //     affiliation: "Google Merchandise Store",
    //     coupon: "SUMMER_FUN",
    //     currency: "USD",
    //     discount: 2.22,
    //     index: 0,
    //     item_brand: "Google",
    //     item_category: "Apparel",
    //     item_category2: "Adult",
    //     item_category3: "Shirts",
    //     item_category4: "Crew",
    //     item_category5: "Short sleeve",
    //     item_list_id: "related_products",
    //     item_list_name: "Related Products",
    //     item_variant: "green",
    //     location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
    //     price: 9.99,
    //     quantity: 1
    //   }
    // ]
    this.pingHome(hit);

    })
    
      
    // this.pingHome(items);

  }
  addToCartGTMGuest(data:any ){
    //(data)
  
    data.forEach(e=>{
      const hit={
         event:'add_to_cart',
         'ecommerce':{
          currency: "CAD",
          value:e.price*e.quantity,
          items: {
            'item_id':e.productId,
            'item_name': e.productName,
            affiliation:"Local",
            coupon: "",
            currency: "CAD",
            discount: 0,
            index: 0,
            item_brand: 0,
            item_category: "item_category",
            item_category2: "item_category2",
            item_category3: "item_category3",
            item_category4: "item_category4",
            item_category5: "item_category5",
            item_list_id: e.productId,
            item_list_name: e.productName,
            item_variant: "Local",
            location_id: "location",
       'price':e.price*e.quantity,
       'quantity': e.quantity
        }
  
         }
    }
    this.pingHome(hit);

    })
    
      
    // this.pingHome(items);

  }

  itemcheckoutGTM(data){
    //(data)
    data.forEach(e=>{
      const hit={
         event:'begin_checkout',
         'ecommerce':{
          currency: "CAD",
          value: e.price*e.selectQuantity,
          coupon: "",
          items: [
            {
              item_id:e.inventoryId,
              item_name:e.productName,
              affiliation: "Local",
              coupon: "",
              currency: "CAD",
              discount:0,
              index: 0,
              item_brand: "",
              item_category: "",
              item_category2: "",
              item_category3: "",
              item_category4: "",
              item_category5: "",
              item_list_id: "",
              item_list_name: e.productName,
              item_variant: "",
              location_id: "",
              price: e.price*e.selectQuantity,
              quantity: e.selectQuantity
            }
          ]
         }
   
      
    }
    this.pingHome(hit);

    })
    
  }
  guestitemcheckoutGTM(data){
    //(data)
    data.forEach(e=>{
      const hit={
         event:'begin_checkout',
         'ecommerce':{
          currency: "CAD",
          value: e.price*e.quantity,
          coupon: "",
          items: [
            {
              item_id:e.productId,
              item_name:e.productName,
              affiliation: "Local",
              coupon: "",
              currency: "CAD",
              discount:0,
              index: 0,
              item_brand: "",
              item_category: "",
              item_category2: "",
              item_category3: "",
              item_category4: "",
              item_category5: "",
              item_list_id: "",
              item_list_name: e.productName,
              item_variant: "",
              location_id: "",
              price:e.price*e.quantity,
              quantity: e.quantity
            }
          ]
         }
    
  }
    this.pingHome(hit);

    })
    
  }

  logCustomDimensionTest(value)
  {
      const hit = {
          event:'custom-dimension',
          value:value
      }
      this.pingHome(hit);
  }  
}
