import * as DemoActions from './app.actions';
import { HomePageContent } from './app.modal';

const initialState: HomePageContent = {
    data: null,
    productDetailPageData: null,
    userRegPageData: null
}

export function reducer(state: HomePageContent[] = [initialState], action: DemoActions.Actions) {
    switch(action.type) {
        case DemoActions.ADD_DEMO:
            return [...state, action.data];
        case DemoActions.REMOVE_DEMO:
            state.splice(action.data, 1);
            return state;
        default:
            return state;
    }
}