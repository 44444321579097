import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, throwError } from "rxjs";

import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { BehaviorService } from "../services/behaviour.service";
import { LoaderModalService } from "../services/loader.service";
import { CommonService } from "../services/common.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  count: number = 1;
  constructor(public router: Router, private bs: BehaviorService, private ls: LoaderModalService, private commonService: CommonService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err, i) => {
        if (err?.status == 0) {
          return;
        }
        if (err?.url.match("sitemap")) {
          return;
        }
        if (err?.status === 404 && err?.statusText == "Not Found" && err?.url.match(window.location.host)) {
          this.count += 1;
          if (this.count == 2) {
            this.ls.startLoader('','')
            this.commonService.getSiteData();
            setTimeout(() => {
              this.commonService.pickUpStore("").subscribe((res) => {  
                  if (res?.length) {
                    let store: any = res[0];
                    localStorage.setItem("localStoreId", store?.Id);
                    localStorage.setItem("storeCurrentAddress", store.Address?.AddressLine2);
                    localStorage.setItem("addressString", store.Address?.AddressLine2);
                    localStorage.setItem("addressForOrderPlace", store.Address?.AddressLine2);
                    localStorage.setItem("addressStatus", "true");
                    localStorage.setItem("addressLatitude", store.location?.coordinates[1]);
                    localStorage.setItem("addressLongitude", store.location?.coordinates[0]);
                    // localStorage.setItem("postal_code", zip);
                    localStorage.setItem("city", store.Address?.City);
                    localStorage.setItem("state", store.Address?.StateName);
                    localStorage.setItem("stateCode", store.Address?.StateCode);
                    localStorage.setItem("country", store.Address?.CountryName);
                    localStorage.setItem("countryCode", store.Address?.CountryCode);
                    localStorage.setItem("dilveryStatus", "true");
                    localStorage.setItem("localStoreName", store?.Name);
                    window.location.reload();
                  }
              });
            }, 500);

            return;
          }
        }

   
        if (err?.error && err?.error?.meta?.msg == "Session Expired.") {
          this.ls.startLoader("", "");
          this.ls.changeStateandStopLoader("error", err?.error["meta"]["msg"], 2000);
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigateByUrl("/verify/age").then((res) => {
          });
        }        
        
        if (err?.status === 401) {
          if (err?.error?.message && err?.error?.message !== "") {
          } else if (err?.error?.error?.message && err?.error?.error?.message !== "") {
          }

          this.router.navigateByUrl("/");
          return throwError(err.error);
        } else if (err.status == 400) {
          if (err?.error?.message && err?.error?.message !== "") {
          } else if (err?.error?.error?.message && err?.error?.error?.message !== "") {
          }
        } else if (err.status == 404) {
          if (err?.error?.message && err?.error?.message !== "") {
          } else if (err?.error?.error?.message && err?.error?.error?.message !== "") {
          }
        } else if (err?.status === 500) {
          if (err?.error?.message !== "") {
            // this.toastr.error(err.error.message,'Error');
          } else if (err?.error?.error?.message !== "") {
            // this.toastr.error(err.error.error.message,'Error');
          }
        }
        // const error = err.error.message || err.code;
        return throwError(err?.error);
      })
    );
  }
}
