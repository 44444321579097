export const environment = {
  production: true,
  // firebase: {
  //   apiKey: "AAAAyCmj5ks:APA91bHMJ2ARJ_F9heZ5lslsBhxiwi_fmu9p0P4oNpLSBUVjrXpsoY6miBCqNyJVBrR7Fn-aCOVz2wze48uwtEwOaVzEfHksALSBozqyV53S6-MJ2FOE36NwNvQeuQyBmJdhQaorCu-K",
  //   authDomain: "project-859692066379.firebaseapp.com",
  //   databaseURL: "https://localcannabias-cb2af.firebaseio.com/",
  //   projectId: "project-hisocietyconsumer",
  //   storageBucket: "project-859692066379.appspot.com",
  //   messagingSenderId: "859692066379"
  // },
  chatUrl:'https://api.cannalogic.co/',
  accessToken : 'pk.eyJ1Ijoic2V2ZW5zdGFyd2ViIiwiYSI6ImNtMzQzbGxhdTFoMDAya3M3ZXF2YXV6MmMifQ.pYT8P91An9jldBYBnOeokQ'

};
