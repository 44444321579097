import { Action } from '@ngrx/store';
import { HomePageContent } from './app.modal';

export const ADD_DEMO = 'HomepageContent';
export const REMOVE_DEMO = 'Remove Demo';

export class Add implements Action {
    readonly type = ADD_DEMO;

    constructor(public data: HomePageContent) {}
}

export class Remove implements Action {
    readonly type = REMOVE_DEMO;

    constructor(public data: any) {}
}

export type Actions = Add | Remove;