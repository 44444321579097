import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productnamefilter'
})
export class ProductnamefilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    var updateString = this.removeLastComma(searchText)
    let brandString = updateString.split(",")
    // items = [...items.filter(item => brandString.includes(item.productName.toLowerCase()))]
    items = [...items.filter(item => item.productName.toLowerCase().includes(brandString))]
    return items
  }

  removeLastComma(data) {
    var n = data.lastIndexOf(",");
    var a = data.substring(0, n)
    return a;
  }

}
