import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pricefilter'
})
export class PriceFilter implements PipeTransform {

  transform(arr: Array<any>, prop: any, reverse: String): any {
    let reverseString;
    if (reverse !== undefined) {
      if (reverse === "false") {
        reverseString = false
      }
      else {
        reverseString = true
      }
    }
    // return null;
    if (arr === undefined) return
    const m = reverseString ? -1 : 1
    return arr.sort((a: any, b: any): number => {
      const x = prop ? a[prop] : ''
      const y = prop ? b[prop] : ''
      return (x === y) ? 0 : (x < y) ? -1 * m : 1 * m
    })
  }
}
