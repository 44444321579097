import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private auth = new BehaviorSubject(false);
  private user = new BehaviorSubject(<any>null);
  private store = new BehaviorSubject(<any>null)
  private mobileVerified = new BehaviorSubject(<any>null)
  private governmentVerified = new BehaviorSubject(<any>null)
  private cartMessage = new BehaviorSubject(<any>null)
  private shopLink = new BehaviorSubject(<any>null)
  private userAddedFromCSV = new BehaviorSubject(<any>null)
  emittedCartValue = new BehaviorSubject(false);

 emmitedCartVar = new BehaviorSubject(false);

  constructor() { }

  getAuth(): Observable<boolean> {
    return this.auth.asObservable();
  }

  getUser(): Observable<any> {
    return this.user.asObservable();
  }
  emitCartForAeropay(): Observable<boolean> {
    return this.emmitedCartVar.asObservable();
  }

  updateGetUserAddedFromCSV(data) {
    this.userAddedFromCSV.next(data)
  }

  getUserAddedFromCSV(): Observable<any> {
    return this.userAddedFromCSV.asObservable()
  }

  getMobileVerified(): Observable<any> {
    return this.mobileVerified.asObservable()
  }

  updateMobileVerified(data) {
    this.mobileVerified.next(data)
  }

  getGovernmentVerified(): Observable<any> {
    return this.governmentVerified.asObservable()
  }

  updateGovernmentVerified(data) {
    this.governmentVerified.next(data)
  }

  updateShopLink(data) {    
    this.shopLink.next(data)
  }

  getShopLink(): Observable<any> {
    return this.shopLink.asObservable()
  }

  getCartMessage(): Observable<any> {
    return this.cartMessage.asObservable()
  }

  updateCartMessage(data) {
    this.cartMessage.next(data)
  }

  // If login is true
  updateUserAuth(authState: boolean, user: any) {
    this.auth.next(authState);
    this.user.next(user);
  }

  updateStoreDetails(store) {
    this.store.next(store)
  }

  getStore(): Observable<any> {
    return this.store.asObservable()
  }

}
