import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alphabet-sort',
  templateUrl: './alphabet-sort.component.html',
  styleUrls: ['./alphabet-sort.component.css']
})
export class AlphabetSortComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
